<template>
  <LiefengContent>
    <template v-slot:title>机构维度统计</template>
    <template v-slot:contentArea>
      <!--上边三图结构-->
      <div class="container">
        <!--左边的上下结构div-->
        <div class="container-left">
          <!--数字结构-->
          <Card>
            <div class="num-div">
              <div class="update-time">
                以下数据更新至
                <span>{{sevenTime.endTime}}</span>
              </div>
              <div class="num-title">
                <div class="item-num">
                  <div class="num">{{numData.childMenuTotal || '0'}}</div>
                  <div class="title">{{'子栏目数量'}}</div>
                </div>
                <div class="item-num">
                  <div class="num">{{numData.menuTotal || '0'}}</div>
                  <div class="title">{{'栏目数量'}}</div>
                </div>
                <div class="item-num">
                  <div class="num">{{numData.publishPv || '0'}}</div>
                  <div class="title">{{'发布中的信息'}}</div>
                </div>
                <div class="item-num">
                  <div class="num-weight">{{numData.userAmount || '0'}}</div>
                  <div class="title">{{'机构用户'}}</div>
                </div>
              </div>
            </div>
          </Card>
          <!--折线图-->
          <Card style="margin-top:10px">
            <div class="link-div">
              <div class="select-div">
                <Select style="width:200px" v-model="userGrandList.dayModel" @on-change="changeDay">
                  <Option
                    v-for="item in operateList"
                    :value="item.value"
                    :key="item.value"
                  >{{ item.label }}</Option>
                </Select>
                <span
                  style="line-height:30px;margin-left:10px"
                  v-if="userGrandList.dayModel == '0'"
                >{{sevenGrandTime.startDate}}-{{sevenGrandTime.endDate}}</span>
                <span
                  style="line-height:30px;margin-left:10px"
                  v-if="userGrandList.dayModel == '1'"
                >{{monthGrandTime.startDate}}-{{monthGrandTime.endDate}}</span>
                <DatePicker
                  v-if="userGrandList.dayModel == '2'"
                  format="yyyy/MM/dd"
                  type="daterange"
                  placement="bottom-end"
                  placeholder="选择时间区域"
                  style="width: 250px;margin-left:10px"
                  @on-change="changeDate"
                ></DatePicker>

                <Select
                  style="width:100px;margin-left:auto"
                  v-model="userGrandList.monthModel"
                  @on-change="monthChange"
                >
                  <Option
                    v-for="item in monthList"
                    :value="item.value"
                    :key="item.value"
                  >{{ item.label }}</Option>
                </Select>
              </div>
              <div class="select-line" style="text-align:center;font-weight:bold;font-size:16px">
                {{'机构用户'}}
                <VeLine :settings="chartSettings" :data="userStatData"></VeLine>
              </div>
            </div>
          </Card>
        </div>
        <!--右边的div-->
        <!-- <div class="container-right">
          <Card style="width:100%;min-height:691px;">
            <div class="update-time">
              以下数据更新至
              <span>{{sevenTime.endTime}}</span>
            </div>
            <Table style="height:calc(100%-100px)" :columns="peopleOrgColumn" :data="peopleData"></Table>
          </Card>
        </div>-->
      </div>
      <!--中间占满一图结构-->
      <!--引入用户图-->
      <div class="content">
        <Card>
          <div class="link-div">
            <div class="select-div">
              <Select
                style="width:200px"
                v-model="userIntroduceList.dayModel"
                @on-change="introuduceChangeDay"
              >
                <Option
                  v-for="item in operateList2"
                  :value="item.value"
                  :key="item.value"
                >{{ item.label }}</Option>
              </Select>
              <span
                style="line-height:30px;margin-left:10px"
                v-if="userIntroduceList.dayModel == '0'"
              >{{userIntroTime.startDate}}-{{userIntroTime.endDate}}</span>
              <span
                style="line-height:30px;margin-left:10px"
                v-if="userIntroduceList.dayModel == '1'"
              >{{userIntroMonth.startDate}}-{{userIntroMonth.endDate}}</span>
              <DatePicker
                v-if="userIntroduceList.dayModel == '2'"
                format="yyyy/MM/dd"
                type="daterange"
                placement="bottom-end"
                placeholder="选择时间区域"
                style="width: 250px;margin-left:10px"
                @on-change="introduceDateChange"
              ></DatePicker>
              <Select
                style="width:100px;margin-left:auto"
                v-model="userIntroduceList.monthModel"
                @on-change="ntroduceMonthChange"
              >
                <Option
                  v-for="item in monthList"
                  :value="item.value"
                  :key="item.value"
                >{{ item.label }}</Option>
              </Select>
            </div>
            <div class="select-line" style="text-align:center;font-weight:bold;font-size:16px">
              {{'引入用户'}}
              <VeLine :settings="chartSettings" :data="institutionalUserData"></VeLine>
            </div>
          </div>
        </Card>
      </div>
      <!--栏目类型当前统计-->
      <div class="content" style="margin-top:20px">
        <Card>
          <div class="link-div">
            <!-- <div class="select-div">
              <Select
                style="width:200px"
                v-model="userColumnsList.dayModel"
                @on-change="changeColumnsDay"
              >
                <Option
                  v-for="item in operateList"
                  :value="item.value"
                  :key="item.value"
                >{{ item.label }}</Option>
              </Select>
              <span
                style="line-height:30px;margin-left:10px"
                v-if="userColumnsList.dayModel == 'WEEK'"
              >{{sevenTime.startTime}}-{{sevenTime.endTime}}</span>
              <span
                style="line-height:30px;margin-left:10px"
                v-if="userColumnsList.dayModel == 'MONTH'"
              >{{monthTime.startTime}}-{{monthTime.endTime}}</span>
              <DatePicker
                v-if="userColumnsList.dayModel == 'OTHER'"
                format="yyyy/MM/dd"
                type="daterange"
                placement="bottom-end"
                placeholder="选择时间区域"
                style="width: 250px;margin-left:10px"
                @on-change="columnChange"
              ></DatePicker>
            </div>-->
          </div>
          <div style="text-align:center;font-weight:bold;font-size:16px">{{'栏目类型当前统计'}}</div>
          <VeHistogram :extend="hisExtendData" :data="institutionalData"></VeHistogram>
        </Card>
      </div>
      <!--运营数据统计-->
      <div class="content" style="margin-top:20px">
        <Card>
          <div class="link-div">
            <div class="select-div">
              <Select
                style="width:200px"
                v-model="userOperaList.dayModel"
                @on-change="operateChange"
              >
                <Option
                  v-for="item in operateList"
                  :value="item.value"
                  :key="item.value"
                >{{ item.label }}</Option>
              </Select>
              <span
                style="line-height:30px;margin-left:10px"
                v-if="userOperaList.dayModel == '0'"
              >{{sevenOperaTime.startTime}}-{{sevenOperaTime.endTime}}</span>
              <span
                style="line-height:30px;margin-left:10px"
                v-if="userOperaList.dayModel == '1'"
              >{{monthOperaTime.startTime}}-{{monthOperaTime.endTime}}</span>
              <DatePicker
                v-if="userOperaList.dayModel == '2'"
                format="yyyy/MM/dd"
                type="daterange"
                placement="bottom-end"
                placeholder="选择时间区域"
                style="width: 250px;margin-left:10px"
                @on-change="operateDataChange"
              ></DatePicker>
            </div>
          </div>
          <div style="text-align:center;font-weight:bold;font-size:16px">{{'运营数据统计'}}</div>
          <VeHistogram :extend="hisExtend" :data="institutionalDataOne"></VeHistogram>
        </Card>
      </div>
      <!--信息更新频率-->
      <!-- <div class="content" style="margin-top:20px">
        <Card>
          <div class="link-div">
            <div class="select-div">
              <Select
                style="width:200px"
                v-model="userInformationList.dayModel"
                @on-change="informationChangeData"
              >
                <Option
                  v-for="item in operateList"
                  :value="item.value"
                  :key="item.value"
                >{{ item.label }}</Option>
              </Select>
              <span
                style="line-height:30px;margin-left:10px"
                v-if="userInformationList.dayModel == 'WEEK'"
              >{{sevenTime.startTime}}-{{sevenTime.endTime}}</span>
              <span
                style="line-height:30px;margin-left:10px"
                v-if="userInformationList.dayModel == 'MONTH'"
              >{{monthTime.startTime}}-{{monthTime.endTime}}</span>
              <DatePicker
                v-if="userInformationList.dayModel == 'OTHER'"
                format="yyyy/MM/dd"
                type="daterange"
                placement="bottom-end"
                placeholder="选择时间区域"
                style="width: 250px;margin-left:10px"
                @on-change="changeInformation"
              ></DatePicker>

              <Select
                style="width:100px;margin-left:auto"
                v-model="userInformationList.monthModel"
                @on-change="informationMonthChange"
              >
                <Option
                  v-for="item in monthList"
                  :value="item.value"
                  :key="item.value"
                >{{ item.label }}</Option>
              </Select>
            </div>
          </div>
          <div style="text-align:center;font-weight:bold;font-size:16px">{{'信息更新频率'}}</div>
          <VeLine :data="institutionalData"></VeLine>
        </Card>
    

        <div class="table-div">
          
          <div class="table-left">
            <Card style="width:100%;height:691px;overflow:scroll">
              <div class="update-time">
                <div>&nbsp;</div>
                <div class="table-title">{{'用户访问排行版'}}</div>
                <div class="table-btn">{{'完整排行'}}</div>
              </div>
              <Table style="height:calc(100%-100px)" :columns="peopleColumn" :data="peopleData"></Table>
            </Card>
          </div>
          <div class="table-right">
            <Card style="width:100%;height:691px;overflow:scroll">
              <div class="update-time">
                <div>&nbsp;</div>
                <div class="table-title">{{'用户报名排行版'}}</div>
                <div class="table-btn">{{'完整排行'}}</div>
              </div>
              <Table style="height:calc(100%-100px)" :columns="peopleColumn" :data="peopleData"></Table>
            </Card>
          </div>
        </div>
      </div>-->
    </template>
  </LiefengContent>
</template>

<script>
//@route('/chartcommittee')
// VeLine:折线图  VePie:饼图  VeHistogram:柱状图
import LiefengContent from "@/components/LiefengContent3";
import VeLine from "v-charts/lib/line.common";
import VePie from "v-charts/lib/pie.common";
import VeHistogram from "v-charts/lib/histogram.common";
export default {
  data() {
    return {
      chartSettings: {
        area: true,
        itemStyle: {
          //面积图颜色设置
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "rgba(254,106,172,0.6)" // 0% 处的颜色
              },
              {
                offset: 1,
                color: "rgba(255,139,119,0.1)" // 100% 处的颜色
              }
            ],
            globalCoord: false // 缺省为 false
          }
        }
      },
      numData: {},
      dayModel: "0", //选择第几天
      operateList: [
        { label: "全部", value: "3" },
        { label: "最近7天", value: "0" },
        { label: "最近30天", value: "1" },
        { label: "自定义", value: "2" }
      ],
      operateList2: [
        { label: "全部", value: "3" },
        { label: "最近7天", value: "0" },
        { label: "最近30天", value: "1" },
        { label: "自定义", value: "2" }
      ],
      selectdate: [], //选择的时间段
      monthModel: "", //力度
      monthList: [
        { label: "全部", value: "0" },
        { label: "周粒度", value: "1" },
        { label: "月粒度", value: "2" },
        { label: "年粒度", value: "3" }
      ],
      sevenTime: {
        startTime: "",
        endTime: ""
      },
      monthTime: {
        startTime: "",
        endTime: ""
      },
      // 各人群数量结束
      institutionalData: {
        columns: ["机构", "栏目数", "子栏目数"],
        rows: []
      },
      hisExtendData: {
        series: {
          barMaxWidth: 50
        },
        dataZoom: [
          {
            show: true,
            realtime: true,
            start: 0,
            end: 50
          },
          {
            type: "inside",
            realtime: true,
            start: 0,
            end: 50
          }
        ]
      },
      peopleOrgColumn: [
        {
          title: "时间",
          key: "time",
          align: "center"
        },
        {
          title: "人数",
          key: "count",
          align: "center"
        }
      ],
      peopleOrgData: [],
      peopleColumn: [
        {
          title: "机构人员",
          key: "name",
          align: "center"
        },
        {
          title: "最近登录时间",
          key: "time",
          align: "center"
        },
        {
          title: "本月累计使用",
          key: "count",
          align: "center"
        }
      ], //右边表格的列数据
      peopleData: [
        {
          name: "白蘑菇",
          time: "2016-10-03",
          count: "2"
        },
        {
          name: "白蘑菇",
          time: "2016-10-03",
          count: "2"
        },
        {
          name: "白蘑菇",
          time: "2016-10-03",
          count: "2"
        }
      ],
      // 柱状图的样式
      hisExtend: {
        series: {
          barMaxWidth: 50
        }
      },
      params: {},

      // 引入用户数据开始
      userIntroduceList: {
        dayModel: "3",
        startDate: "",
        endDate: "",
        monthModel: ""
      },
      userIntroTime: {
        startDate: "",
        endDate: ""
      },
      userIntroMonth: {
        startDate: "",
        endDate: ""
      },
      changeUserDate: {
        startDate: "",
        endDate: ""
      },
      institutionalUserData: {
        columns: ["时间", "数量"],
        rows: []
      },
      // 引入用户结束
      // 机构用户开始
      userStatData: {
        columns: ["时间", "数量"],
        rows: []
      },
      sevenGrandTime: {
        startDate: "",
        endDate: ""
      },
      monthGrandTime: {
        startDate: "",
        endDate: ""
      },
      userGrandList: {
        dayModel: "3",
        startDate: "",
        endDate: "",
        monthModel: ""
      },
      changeGrandDate: {
        startDate: "",
        endDate: ""
      },

      // 机构用户结束
      userColumnsList: {
        dayModel: "WEEK",
        startDate: "",
        endDate: "",
        monthModel: ""
      },
      userOperaList: {
        dayModel: "3",
        startDate: "",
        endDate: "",
        monthModel: ""
      },
      sevenOperaTime: {
        startTime: "",
        endTime: ""
      },
      monthOperaTime: {
        startTime: "",
        endTime: ""
      },
      userInformationList: {
        dayModel: "WEEK",
        startDate: "",
        endDate: "",
        monthModel: ""
      },
      // 用户机构折线图数据
      userInstitutionalData: {
        columns: ["内容分类", "发布数"],
        rows: [
          // { 内容分类: "信息类", 发布数: 1393 },
          // { 内容分类: "图文类", 发布数: 3530 },
          // { 内容分类: "音视频", 发布数: 2923 },
          // { 内容分类: "店铺类", 发布数: 1723 },
          // { 内容分类: "地图类", 发布数: 3792 },
          // { 内容分类: "直播", 发布数: 4593 },
          // { 内容分类: "普通活动", 发布数: 3593 },
          // { 内容分类: "预约活动", 发布数: 5593 },
          // { 内容分类: "服务类", 发布数: 7593 }
        ]
      },
      institutionalDataOne: {
        columns: ["机构", "浏览数", "点赞数", "分享数", "参与人数"],
        rows: []
      }
    };
  },
  methods: {
    //   方法部分
    // 选择了7天/半月选择框事件
    changeDay(value) {
      let data = {
        endDate: "",
        startDate: "",
        timeGranularity: this.userGrandList.monthModel
      };
      if (value == 0) {
        data.endDate = this.sevenGrandTime.endDate;
        data.startDate = this.sevenGrandTime.startDate;
      } else if (value == 1) {
        data.endDate = this.monthGrandTime.endDate;
        data.startDate = this.monthGrandTime.startDate;
      } else if (value == 3) {
        data.endDate = "";
        data.startDate = "";
      }
      this.getStatUserByDate(data);
    },
    // 机构用户点击了自定义时间
    changeDate(value) {
      value[0] = this.$core.formatDate(new Date(value[0]), "yyyy-MM-dd");
      value[1] = this.$core.formatDate(new Date(value[1]), "yyyy-MM-dd");
      this.changeGrandDate.startDate = value[0];
      this.changeGrandDate.endDate = value[1];
      let data = {
        endDate: value[1],
        timeGranularity: this.userGrandList.monthModel,
        startDate: value[0]
      };
      this.getStatUserByDate(data);
    },
    // 选择了日/周的粒度
    monthChange(value) {
      let data = {
        endDate: "",
        startDate: "",
        timeGranularity: this.userGrandList.monthModel
      };
      if (this.userGrandList.dayModel == 0) {
        data.endDate = this.sevenGrandTime.endDate;
        data.startDate = this.sevenGrandTime.startDate;
      } else if (this.userGrandList.dayModel == 1) {
        data.endDate = this.monthGrandTime.endDate;
        data.startDate = this.monthGrandTime.startDate;
      } else if (this.userGrandList.dayModel == 2) {
        data.endDate = this.changeGrandDate.endDate;
        data.startDate = this.changeGrandDate.startDate;
      }
      if (this.userGrandList.monthModel == 0) {
        data.timeGranularity = "";
      }
      this.getStatUserByDate(data);
      return;
    },

    // 引入用户的方法

    // 选择周或月
    introuduceChangeDay(value) {
      console.log("选择了啥", value);
      let data = {
        startDate: "",
        endDate: "",
        timeGranularity: this.userIntroduceList.monthModel
      };
      if (this.userIntroduceList.dayModel == 0) {
        data.startDate = this.userIntroTime.startDate;
        data.endDate = this.userIntroTime.endDate;
      } else if (this.userIntroduceList.dayModel == 1) {
        data.startDate = this.userIntroMonth.startDate;
        data.endDate = this.userIntroMonth.endDate;
      } else if (this.userIntroduceList.dayModel == 3) {
        data.startDate = "";
        data.endDate = "";
      }
      this.getStatIntroduceByDate(data);
    },
    // 粒度选择框
    ntroduceMonthChange(value) {
      let data = {
        endDate: "",
        startDate: "",
        timeGranularity: this.userIntroduceList.monthModel
      };
      if (this.userIntroduceList.dayModel == 0) {
        data.endDate = this.userIntroTime.endDate;
        data.startDate = this.userIntroTime.startDate;
      } else if (this.userIntroduceList.dayModel == 1) {
        data.endDate = this.userIntroMonth.endDate;
        data.startDate = this.userIntroMonth.startDate;
      } else if (this.userIntroduceList.dayModel == 2) {
        data.endDate = this.changeUserDate.endDate;
        data.startDate = this.changeUserDate.startDate;
      }
      if (this.userIntroduceList.monthModel == 0) {
        data.timeGranularity = "";
      }
      this.getStatIntroduceByDate(data);
      return;
    },
    // 选择了自定义时间段
    introduceDateChange(value) {
      value[0] = this.$core.formatDate(new Date(value[0]), "yyyy-MM-dd");
      value[1] = this.$core.formatDate(new Date(value[1]), "yyyy-MM-dd");
      this.changeUserDate.startDate = value[0];
      this.changeUserDate.endDate = value[1];
      let data = {
        endDate: value[1],
        timeGranularity: this.userIntroduceList.monthModel,
        startDate: value[0]
      };
      this.getStatIntroduceByDate(data);
    },
    //栏目统计
    //自定义时间的选择
    columnChange(val) {
      this.userColumnsList.startDate = val[0];
      this.userColumnsList.endDate = val[1];
      let dateType = "";
      if (this.userColumnsList.dayModel != "OTHER") {
        dateType = this.userColumnsList.dayModel;
      } else {
        dateType = "";
      }
      let data = {
        dateType: dateType,
        granularity: this.userColumnsList.monthModel,
        startDate: this.userColumnsList.startDate,
        endDate: this.userColumnsList.endDate
      };
      this.getColumnsData(data);
    },
    // 选择的是7天一个月还是自定义
    changeColumnsDay(value) {
      this.userColumnsList.dayModel = value;
      let dateType = "";
      if (this.userColumnsList.dayModel != "OTHER") {
        dateType = this.userColumnsList.dayModel;
        let data = {
          dateType: dateType,
          granularity: this.userColumnsList.monthModel,
          startDate: this.userColumnsList.startDate,
          endDate: this.userColumnsList.endDate
        };
        this.getColumnsData(data);
      } else {
        dateType = "";
      }
    },

    // 运营数据
    operateChange(value) {
      let data = {
        startDate: "",
        endDate: ""
      };
      if (value == 0) {
        data.startDate = this.sevenOperaTime.startTime;
        data.endDate = this.sevenOperaTime.endTime;
      } else if (value == 1) {
        data.startDate = this.monthOperaTime.startTime;
        data.endDate = this.monthOperaTime.endTime;
      } else if (value == 3) {
        data.startDate = "";
        data.endDate = "";
      }
      this.getGroupBusiness(data);
    },

    operateDataChange(val) {
      val[0] = this.$core.formatDate(new Date(val[0]), "yyyy-MM-dd");
      val[1] = this.$core.formatDate(new Date(val[1]), "yyyy-MM-dd");
      this.userOperaList.startDate = val[0];
      this.userOperaList.endDate = val[1];
      let data = {
        startDate: this.userOperaList.startDate,
        endDate: this.userOperaList.endDate
      };
      this.getGroupBusiness(data);
      // this.getColumnsData(data);
    },
    // 信息频率
    informationChangeData(value) {
      this.userInformationList.dayModel = value;
      let dateType = "";
      if (this.userInformationList.dayModel != "OTHER") {
        dateType = this.userInformationList.dayModel;
        let data = {
          dateType: dateType,
          granularity: this.userInformationList.monthModel,
          startDate: this.userInformationList.startDate,
          endDate: this.userInformationList.endDate
        };
        // this.getInformationPublich(data);
      } else {
        dateType = "";
      }
    },
    changeInformation(val) {
      this.userInformationList.startDate = val[0];
      this.userInformationList.endDate = val[1];
      let dateType = "";
      if (this.userInformationList.dayModel != "OTHER") {
        dateType = this.userInformationList.dayModel;
      } else {
        dateType = "";
      }
      let data = {
        dateType: dateType,
        granularity: this.userInformationList.monthModel,
        startDate: this.userInformationList.startDate,
        endDate: this.userInformationList.endDate
      };
      // this.getInformationPublich(data);
    },
    informationMonthChange(value) {
      this.userInformationList.monthModel = value;
      let dateType = "";
      if (this.userInformationList.dayModel != "OTHER") {
        dateType = this.userInformationList.dayModel;
      } else {
        dateType = "";
      }
      let data = {
        dateType: dateType,
        granularity: this.userInformationList.monthModel,
        startDate: this.userInformationList.startDate,
        endDate: this.userInformationList.endDate
      };
      // this.getInformationPublich(data);
    },
    //接口部分
    // 获取头部上的数据
    getManageBase() {
      this.$get("old/api/pc/sy/stat/statOrgManageBase", {
        ...this.params
      }).then(res => {
        // this.numData = res.data;
      });
    },

    getGroupBusiness(data) {
      this.$get(
        "/statistic/api/symanage/pc/statColumn/statColumnBehaviorGroupBusiness",
        {
          endDate: data.endDate,
          oemCode: parent.vue.oemInfo.oemCode,
          orgCode: parent.vue.loginInfo.userinfo.orgCode,
          orgLevel: "",
          startDate: data.startDate,
          statType: 1
        }
      ).then(res => {
        if (res.code == 200) {
          let data = res.data;
          this.institutionalDataOne = {
            columns: ["机构", "浏览数", "点赞数", "分享数", "参与人数"],
            rows: []
          };
          if (data.xaxis.length != 0) {
            data.xaxis.map((item, index) => {
              this.institutionalDataOne.rows.push({ 机构: item.businessName });
            });
            data.yaxis.map((item2, index) => {
              this.institutionalDataOne.rows[index].浏览数 = item2.browsePv;
              this.institutionalDataOne.rows[index].点赞数 = item2.likePv;
              this.institutionalDataOne.rows[index].分享数 = item2.sharePv;
              this.institutionalDataOne.rows[index].参与人数 =
                item2.participatePv;
            });

            console.log("获取的数据", this.institutionalDataOne);
          }
        } else {
          this.$Message.error({
            content: res.desc,
            background: true
          });
          return;
        }
      });
    },
    // 获取机构引入社区累计用户数对比
    getStatIntroduceByDate(data) {
      this.$get("/statistic/api/symanage/pc/userIncrease/statIntroduceByDate", {
        endDate: data.endDate,
        oemCode: parent.vue.oemInfo.oemCode,
        orgCode: parent.vue.loginInfo.userinfo.orgCode,
        orgLevel: "",
        startDate: data.startDate,
        statType: 1,
        timeGranularity: data.timeGranularity
      }).then(res => {
        this.institutionalUserData = {
          columns: ["时间", "数量"],
          rows: []
        };
        let data = res.data;
        if (data.xaxis.length != 0) {
          data.xaxis.map(item => {
            this.institutionalUserData.rows.push({ 时间: item.statDate });
          });
          data.yaxis.map((item, index) => {
            this.institutionalUserData.rows[index].数量 = item.introduce;
          });
        }
      });
    },
    getStatUserByDate(data) {
      this.$get("/statistic/api/symanage/pc/userIncrease/statUserByDate", {
        endDate: data.endDate,
        oemCode: parent.vue.oemInfo.oemCode,
        orgCode: parent.vue.loginInfo.userinfo.orgCode,
        orgLevel: "",
        startDate: data.startDate,
        statType: 1,
        timeGranularity: data.timeGranularity
      }).then(res => {
        this.userStatData = {
          columns: ["时间", "数量"],
          rows: []
        };
        let data = res.data;
        if (data.xaxis.length != 0) {
          data.xaxis.map(item => {
            this.userStatData.rows.push({ 时间: item.statDate });
            this.peopleOrgData.push({ time: item.statDate });
          });
          data.yaxis.map((item, index) => {
            this.userStatData.rows[index].数量 = item.userAmount;
            this.peopleOrgData.push({ count: item.userAmount });
          });
        }
      });
    },
    // 头部数据
    getOrgHeadStat() {
      this.$get("/statistic/api/symanage/pc/statHead/orgHeadStat", {
        oemCode: parent.vue.oemInfo.oemCode,
        orgCode: parent.vue.loginInfo.userinfo.orgCode
      }).then(res => {
        this.numData = res.data;
      });
    },
    // 各机构当前栏目统计
    getQueryMenuColumn() {
      this.$get("/statistic/api/symanage/pc/statMenu/queryMenuColumnByType", {
        oemCode: parent.vue.oemInfo.oemCode,
        orgCode: parent.vue.loginInfo.userinfo.orgCode,
        orgLevel: "",
        statType: 1
      }).then(res => {
        let data = res.data;
        data.xaxis.map(item => {
          this.institutionalData.rows.push({ 机构: item.menuTypeName });
        });
        data.yaxis.map((item, index) => {
          this.institutionalData.rows[index].栏目数 = item.menuTotal;
          this.institutionalData.rows[index].子栏目数 = item.childMenuTotal;
        });
      });
    },
    // 格式化时间的公共方法
    // 获取当前时间并且转换为常见格式
    formatterData(value) {
      var date = new Date();
      var year = "";
      var month = "";
      var day = "";
      if (value == 1) {
        year = date.getFullYear();
        month = date.getMonth() + 1;
        day = date.getDate();
      } else if (value == 7 || value == 30) {
        let dates = new Date(date.getTime() - value * 24 * 3600 * 1000);
        year = dates.getFullYear();
        month = dates.getMonth() + 1;
        day = dates.getDate();
      }

      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      let nowTime = year + "-" + month + "-" + day;
      return nowTime;
    }
  },
  created() {
    this.sevenTime = {
      startTime: this.formatterData(7),
      endTime: this.formatterData(1)
    };
    this.monthTime = {
      startTime: this.formatterData(30),
      endTime: this.formatterData(1)
    };
    this.userIntroTime = {
      startDate: this.formatterData(7),
      endDate: this.formatterData(1)
    };
    this.userIntroMonth = {
      startDate: this.formatterData(30),
      endDate: this.formatterData(1)
    };
    this.sevenGrandTime = {
      startDate: this.formatterData(7),
      endDate: this.formatterData(1)
    };
    this.monthGrandTime = {
      startDate: this.formatterData(30),
      endDate: this.formatterData(1)
    };
    this.sevenOperaTime = {
      startTime: this.formatterData(7),
      endTime: this.formatterData(1)
    };
    this.monthOperaTime = {
      startTime: this.formatterData(30),
      endTime: this.formatterData(1)
    };
    this.params = {
      operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
      orgCode: parent.vue.loginInfo.userinfo.orgCode,
      oemCode: parent.vue.oemInfo.oemCode,
      terminal: 3
    };
    // this.getManageBase();
    let data = {
      endDate: "",
      startDate: ""
    };
    this.getGroupBusiness(data);
    let params = {
      endDate: "",
      timeGranularity: ""
    };
    this.getStatIntroduceByDate(params);
    let userParams = {
      endDate: "",
      startDate: "",
      timeGranularity: ""
    };
    this.getStatUserByDate(userParams);
    this.getOrgHeadStat();
    this.getQueryMenuColumn();
  },
  components: {
    LiefengContent,
    VeLine,
    VePie,
    VeHistogram
  }
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: space-between;
  .container-left {
    width: 100%;
    // width:70%
    margin-right: 10px;
    .num-div {
      .update-time {
        font-size: 14px;
      }
      .num-title {
        height: 200px;
        display: flex;
        justify-content: flex-start;
        .item-num {
          flex: 1;
          text-align: center;
          .num {
            padding-top: 50px;
            padding-bottom: 40px;
            font-size: 30px;
            font-weight: bold;
          }
          .num-red {
            font-size: 30px;
            padding-top: 50px;
            padding-bottom: 40px;
            font-weight: bold;
            color: red;
          }
          .num-weight {
            padding-top: 50px;
            padding-bottom: 40px;
            font-weight: bold;
            font-size: 40px;
          }
          .title {
            font-size: 16px;
          }
        }
      }
    }
    .link-div {
      width: 100%;
      height: 400px;
      .select-div {
        display: flex;
        justify-content: flex-start;
      }
    }
  }
  .container-right {
    min-height: 699px;
    width: 30%;
    overflow: scroll;
    .update-time {
      text-align: right;
      font-size: 14px;
    }
  }
}
.content {
  margin-top: 120px;
  .link-div {
    .select-div {
      display: flex;
      justify-content: flex-start;
    }
  }
}
.table-div {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .table-left,
  .table-right {
    width: 49.5%;
    min-height: 699px;
    overflow: scroll;
    .update-time {
      margin-bottom: 5px;
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      text-align: center;
      .table-title {
        padding-left: 53px;
        font-weight: bold;
      }
      .table-btn {
        color: #169bd5;
        cursor: pointer;
        user-select: none;
      }
    }
  }
}
</style>